var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row lead-card"
  }, [_c('div', {
    staticClass: "lead-Search-wrap"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.setFilters.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.search,
      expression: "filters.search"
    }],
    staticClass: "memberSearch-field",
    attrs: {
      "type": "text",
      "placeholder": "Search"
    },
    domProps: {
      "value": _vm.filters.search
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.filters, "search", $event.target.value);
      }
    }
  })])]), _vm._m(1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', [_c('strong', [_vm._v("Search")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row justify-content-md-end"
  }, [_c('div', {
    staticClass: "col-xxl-2 col-xl-3"
  }, [_c('button', {
    staticClass: "univ-btn",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Search")])])]);
}]

export { render, staticRenderFns }
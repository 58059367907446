<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th>Date/Time</th>
        <th v-for="question in renderLeadsList(leadsQuestions, 'question')" :key="question.alias">{{question.question}}</th>
        <th> Actions </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td v-if="checkLeadsList(leadsQuestions) < 1" colspan="6" align="center">
          No records found.
        </td>
      </tr>
      <tr v-for="(answerList, index) in renderLeadsList(leadsQuestions, 'answer')" :key="index">
        <td v-for="(answer, index) in answerList" :key="index">
          {{answer[4].dateTime}}
        </td>
        <td v-for="(answer, index) in answerList" :key="index">
          {{answer[1].answer}}
        </td>
        <td v-for="(answer, index) in answerList" :key="index">
          {{answer[2].answer}}
        </td>
        <td v-for="(answer, index) in answerList" :key="index">
          {{answer[3].answer}}
        </td>
        <td class="action-icons" v-for="(answer, index) in answerList" :key="index">
          <custom-button
              title="View Question List"
              iconClass="fas fa-file"
              @onClicked="openModal(answer[4].deviceId, 'questionListModal')"
          />
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <QuestionsListModal  :deviceId.sync="row"  @onCloseModal="row = null"/>
    </div>
  </div>
</template>

<script>
import QuestionsListModal from "../Modal/QuestionsListModal";
export default {
  name: "LeadQuestionTable",
  props: {
    leadsQuestions: {
      required: false,
    },
    paginationData: {
      required: false,
    },
  },
  components: {
    QuestionsListModal
  },
  data () {
    return {
      leadsListLength: null,
      leadsList: {},
      row: null
    }
  },
  methods: {
    checkLeadsList (leadsQuestions) {
      return leadsQuestions.answer.records.length
    },
    renderLeadsList(leadsList, listType) {
      let renderList = []
      if (listType == 'question') {
        leadsList.question.forEach((result) => {
          let obj = {}
          if (result.id == 1) {
            obj[`${listType}`] = result.question
            if (listType == 'question') obj['alias'] = result.alias
            renderList.splice(1, 0, obj)
          }
          if (result.id == 2) {
            obj[`${listType}`] = result.question
            if (listType == 'question') obj['alias'] = result.alias
            renderList.splice(2, 0, obj)
          }
          if (result.id == 3) {
            obj[`${listType}`] = result.question
            if (listType == 'question') obj['alias'] = result.alias
            renderList.splice(3, 0, obj)
          }
        })
        return renderList
      }
      if (listType == 'answer') {
        let renderList = []
        leadsList.answer.records.forEach((result, index)=> {
          let list = []
          list =  this.renderRecordList(result);
          renderList.splice(index, 0, list)
        })
        return renderList
      }
    },
    renderRecordList (result) {
      let renderList = []
      result.forEach((result) => {
        let list = []
        let obj = {}
        list = this.renderAnswerList(result, 'answer');
        obj['deviceId'] =  result.device_id
        obj['dateTime'] =  result.latest_date
        list.push(obj)
        renderList.push(list)
      })
      return renderList
    },
    renderAnswerList (result, listType) {
      let renderList = [
        {answer: null},
        {answer: null},
        {answer: null},
        {answer: null}
      ]
      result.answer.forEach((ans) => {
        if(ans.question_id == 1) {
          let obj = {}
          obj[`${listType}`] = ans.answer
          obj[`question_id`] = ans.question_id
          obj[`device_id`] = ans.device_id
          renderList[1] = obj
        }
        if(ans.question_id == 2) {
          let obj = {}
          obj[`${listType}`] = ans.answer
          obj[`question_id`] = ans.question_id
          obj[`device_id`] = ans.device_id
          renderList[2] = obj
        }
        if(ans.question_id == 3) {
          let obj = {}
          obj[`${listType}`] = ans.answer
          obj[`question_id`] = ans.question_id
          obj[`device_id`] = ans.device_id
          renderList[3] = obj
        }
      })
      return renderList;
    },
    openModal(row, modalId) {
      this.row = row;
      this.$bvModal.show(modalId);
    }
  }
}
</script>

<style scoped>

</style>






var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Date/Time")]), _vm._l(_vm.renderLeadsList(_vm.leadsQuestions, 'question'), function (question) {
    return _c('th', {
      key: question.alias
    }, [_vm._v(_vm._s(question.question))]);
  }), _c('th', [_vm._v(" Actions ")])], 2)]), _c('tbody', [_c('tr', [_vm.checkLeadsList(_vm.leadsQuestions) < 1 ? _c('td', {
    attrs: {
      "colspan": "6",
      "align": "center"
    }
  }, [_vm._v(" No records found. ")]) : _vm._e()]), _vm._l(_vm.renderLeadsList(_vm.leadsQuestions, 'answer'), function (answerList, index) {
    return _c('tr', {
      key: index
    }, [_vm._l(answerList, function (answer, index) {
      return _c('td', {
        key: index
      }, [_vm._v(" " + _vm._s(answer[4].dateTime) + " ")]);
    }), _vm._l(answerList, function (answer, index) {
      return _c('td', {
        key: index
      }, [_vm._v(" " + _vm._s(answer[1].answer) + " ")]);
    }), _vm._l(answerList, function (answer, index) {
      return _c('td', {
        key: index
      }, [_vm._v(" " + _vm._s(answer[2].answer) + " ")]);
    }), _vm._l(answerList, function (answer, index) {
      return _c('td', {
        key: index
      }, [_vm._v(" " + _vm._s(answer[3].answer) + " ")]);
    }), _vm._l(answerList, function (answer, index) {
      return _c('td', {
        key: index,
        staticClass: "action-icons"
      }, [_c('custom-button', {
        attrs: {
          "title": "View Question List",
          "iconClass": "fas fa-file"
        },
        on: {
          "onClicked": function onClicked($event) {
            return _vm.openModal(answer[4].deviceId, 'questionListModal');
          }
        }
      })], 1);
    })], 2);
  })], 2)]), _c('div', [_c('QuestionsListModal', {
    attrs: {
      "deviceId": _vm.row
    },
    on: {
      "update:deviceId": function updateDeviceId($event) {
        _vm.row = $event;
      },
      "update:device-id": function updateDeviceId($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
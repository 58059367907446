<template>
 <b-modal id="questionListModal"
          modal-class="client-details-modal"
          size="lg"
          hide-footer
          @shown="getQuestionAnswerList()"
 >
   <template #modal-header>
     <div class="view-clients-header">
       <b-button variant="close-btn" @click="closeModal">
         <i class="fas fa-times"></i>
       </b-button>
     </div>
     <b-container fluid ref="questionAnswerListContainer">
       <div class="view-clients-modal-content">
         <h5 class="text-center">View Leads Question & Answer </h5><hr/>
       </div>
       <div class="leads-question-container">
         <div v-for="(item, index) in questionAnswerList" :key="index">
           <div class="mt-3 mb-1"><strong><span class="mr-2">{{index+1}})</span> {{item.assistant_question.question}}</strong></div>
           <div class="mb-2 answer-align">{{item.answer}}</div>
         </div>
       </div>
     </b-container>
   </template>
 </b-modal>
</template>

<script>
import axios from "axios";

export default {
  name: "QuestionsListModal",
  props: {
    deviceId: {
      required: true
    }
  },
  data () {
    return {
       questionAnswerList: []
    }
  },
  methods: {
    closeModal () {
      this.$emit("onCloseModal");
      this.$bvModal.hide("questionListModal");
      this.questionAnswerList = []
    },
    getQuestionAnswerList () {
      let app = this
      app.questionAnswerList = []
      const url = `v2/lead-storage/details?device_id=${app.deviceId}`
      let loader = app.$loading.show({
        container: app.$refs.questionAnswerListContainer
      });
      return axios
          .get(url)
          .then((response) => {
            let sortQuestionAnswerList = []
            sortQuestionAnswerList = response.data.data.answer.sort((a, b) => {
              return a.question_id - b.question_id;
            })
            app.questionAnswerList = sortQuestionAnswerList
            loader.hide();
          });
    }
  }
}
</script>

<style>
.leads-question-container {
  padding: 0 10px;
}
.answer-align {
  padding: 1px 20px;
}
</style>

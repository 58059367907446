import { render, staticRenderFns } from "./LeadQuestionTable.vue?vue&type=template&id=a78f931c&scoped=true&"
import script from "./LeadQuestionTable.vue?vue&type=script&lang=js&"
export * from "./LeadQuestionTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a78f931c",
  null
  
)

export default component.exports
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "questionListModal",
      "modal-class": "client-details-modal",
      "size": "lg",
      "hide-footer": ""
    },
    on: {
      "shown": function shown($event) {
        return _vm.getQuestionAnswerList();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1), _c('b-container', {
          ref: "questionAnswerListContainer",
          attrs: {
            "fluid": ""
          }
        }, [_c('div', {
          staticClass: "view-clients-modal-content"
        }, [_c('h5', {
          staticClass: "text-center"
        }, [_vm._v("View Leads Question & Answer ")]), _c('hr')]), _c('div', {
          staticClass: "leads-question-container"
        }, _vm._l(_vm.questionAnswerList, function (item, index) {
          return _c('div', {
            key: index
          }, [_c('div', {
            staticClass: "mt-3 mb-1"
          }, [_c('strong', [_c('span', {
            staticClass: "mr-2"
          }, [_vm._v(_vm._s(index + 1) + ")")]), _vm._v(" " + _vm._s(item.assistant_question.question))])]), _c('div', {
            staticClass: "mb-2 answer-align"
          }, [_vm._v(_vm._s(item.answer))])]);
        }), 0)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper lead-listing-wrap"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("View Leads")]), _c('button', {
    staticClass: "clear-search",
    on: {
      "click": _vm.clearData
    }
  }, [_vm._v("Clear Search")])]), _c('LeadSearch', {
    attrs: {
      "filters": _vm.filterQuery,
      "clear": _vm.clear
    },
    on: {
      "onSearch": _vm.filterData
    }
  }), _c('div', {
    staticClass: "row lead-card lead-card-list"
  }, [_c('div', {
    staticClass: "clientsData-wrap"
  }, [_c('div', {
    staticClass: "top-pagination"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.paginationData,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.paginationData = $event;
      }
    }
  })], 1), _c('lead-question-table', {
    attrs: {
      "leadsQuestions": _vm.leadsQuestions,
      "paginationData": _vm.paginationData
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.paginationData,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.paginationData = $event;
      }
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<div class="page-wrapper lead-listing-wrap">
  <breadcrumb :items="breadcrumbItems" />
  <div class="page-title">
    <h1>View Leads</h1>
    <button class="clear-search" @click="clearData">Clear Search</button>
  </div>
  <LeadSearch
      :filters="filterQuery"
      :clear="clear"
      @onSearch="filterData" />

  <div class="row lead-card lead-card-list">
    <div class="clientsData-wrap">
      <div class="top-pagination">
        <pagination :rows.sync="paginationData" :filters="filterQuery" />
      </div>
      <lead-question-table  :leadsQuestions="leadsQuestions" :paginationData="paginationData"/>
      <pagination :rows.sync="paginationData" :filters="filterQuery" />
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import LeadSearch from "./Partials/LeadSearch";
import Helper from "@/resource/Helper";
import LeadQuestionTable from "@/views/Leads/Partials/LeadQuestionTable";
const filterData = {
  search: ""
};
export default {
  name: "LeadsQuestionsList",
  components: {
    LeadQuestionTable,
    LeadSearch
  },
  data () {
    return {
      breadcrumbItems: [{title: "View Leads Questions", active: true}],
      filterQuery: { ...filterData },
      leadsQuestions: {},
      pagination: {
        page: 1,
      },
      paginationData: {},
      clear: false
    }
  },
  mounted () {
    let app = this;
    app.getQuestionsLists();
  },
  methods: {
    clearData() {
      this.filterQuery = { ...filterData };
      this.clear = !this.clear;
      this.getQuestionsLists(this.filterQuery);
    },
    filterData(filters) {
      this.filterQuery = filters;
      this.getQuestionsLists(this.filterQuery);
    },
    async getQuestionsLists (filters = []) {
      let app = this;
      let url;
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = this.$endpoint.GET_LEADS_QUE + `?${filterUrl}`;
      } else {
        url = this.$endpoint.GET_LEADS_QUE;
      }
      let loader = this.$loading.show();
      await axios
          .get(url)
          .then((res) => {
            console.log(res)
            app.leadsQuestions = res.data.data;
            app.paginationData = res.data.data.answer;
          })
          .finally(() => {
            loader.hide();
          });
    }
  }
}
</script>

<style src="@/assets/css/lead-listing.css"></style>


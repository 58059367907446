<template>
  <div class="row lead-card">
    <div class="lead-Search-wrap">
      <form @submit.prevent="setFilters">
        <div class="form-group row">
          <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4">
            <label><strong>Search</strong></label>
            <input
                v-model="filters.search"
                type="text"
                class="memberSearch-field"
                placeholder="Search"
            />
          </div>
        </div>
        <div class="row justify-content-md-end">
          <div class="col-xxl-2 col-xl-3">
            <button type="submit" class="univ-btn">Search</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeadSearch",
  props: {
    filters: {
      required: true,
    },
    clear: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    setFilters() {
      this.$emit("onSearch", this.filters);
    }
  }
}
</script>

<style scoped>

</style>
